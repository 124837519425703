import React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';

import {
  Seo,
  Layout,
  PostBox,
  Masonry,
  ZoomImage,
} from '../components';

const ArtPage = ({ data }: PageProps<DataProps>) => {
  const images = [...data.allFile.nodes];
  images.sort(({ name: left }, { name: right }) => left.localeCompare(right));
  return (
  <Layout>
    <PostBox title="Art">
      <Masonry>
        {images.map(({ name, ...imgData }) => {
          const image = getImage(imgData) as IGatsbyImageData;
          return (
            <ZoomImage key={name} name={name}>
              <GatsbyImage
                image={image}
                alt={ name }
                style={{
                  position: 'static',
                  maxHeight: '100%',
                  maxWidth: '100%',
                  height: '100%',
                  width: '100%',
                }}
                imgStyle={{
                  objectFit: 'scale-down',
                }}
              />
            </ZoomImage>
          );
        })}
      </Masonry>
    </PostBox>
  </Layout>
  );
}

export default ArtPage;

export const Head: HeadFC = () => (<Seo title="Art" description="Selected paintings and artwork by Alese Lea Taylor" />);

export const query = graphql`
{
  allFile(
    filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativePath: {regex: "/^art\\//"}}
  ) {
    nodes {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`

type DataProps = {
  allFile: {
    nodes: (ImageDataLike & { name: string, relativePath: string })[],
  },
}
